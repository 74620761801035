<template>
  <div class="project-list-container">
    <div v-if="!eulaModalIsVisible" id="user-bar">
      <marketing-bar />
    </div>

    <b-form-group id='projectTypeFilters'  class="tabs-container mt-5 mb-3 no-top-margin-all-descendants">
      <b-form-radio-group
        v-if="!eulaModalIsVisible"
        :options="projectTabs"
        v-model="currentTab"
        @change="setCurrentTab()"
        buttons
        button-variant="outline"
      >
      </b-form-radio-group>
      
    </b-form-group>
    <hr id='projectTypeDivider'>
    <div class="actions-container" v-if="!eulaModalIsVisible">
    <div class="d-flex flex-row align-items-cener no-top-margin-all-descendants">
      <FormulateInput
        type="search"
        name="project name"
        class="mb-0"
        placeholder="search projects"
        v-model="searchProjectsQueryInput"
        v-on:keyup.enter="searchProjects"
      />
      <b-badge class="ml-2 px-2 d-flex flex-row align-items-center" v-if="searchingProjects">
        <span class="mr-2">{{ searchProjectsQuery }}</span>
        <b-icon
          class="search-badge-x-icon"
          icon="x-circle-fill"
          @click="cancelSearch()"
        ></b-icon>
      </b-badge>
    </div>

    <div class="ordering-section">
      <label for="ordering-select">Order by:</label>
      <select
        id="ordering-select"
        v-model="selectedOrder" 
        @change="updateOrdering"
        class="ordering-select"
      >
        <option 
          v-for="option in orderingOptions" 
          :key="option.value" 
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>

    <div class="button-section">
      <custom-button
        v-if="canAddProject"
        type="button"
        theme="ghost-button"
        @click="openCreateProjectModal()"
      >
        + Add Project
      </custom-button>
    </div>
  </div>

    <!-- List Container with Tab-based Filtering -->
    <div class="list-container" v-if="!eulaModalIsVisible">
      <loading-state v-if="currentTabLoading && !loadingNextPage" />
      <div v-else-if="errorMsg" class="notification-banner-container">
        <notification-banner
          theme="error"
          title="Content Unavailable"
          :alternate="true"
          :message="errorMsg"
          :show-close-button="false"
        />
      </div>
      <empty-state v-else-if="(filteredProjects.length === 0 && !searchingProjects && !currentTabLoading)"
        :image="emptyStateImageAsset"
        image-alt-text="Get Started">
        <template v-slot:title><h2>Get Started</h2></template>
        <template v-slot:message>
          <p>
            Rapid insights to guide your design decisions now, saving you from
            costly mistakes later.
          </p>
        </template>
        <template v-slot:call-to-action>
          <div class="button-row">
            <a class="button" href="https://rwdi.com/contact" target="_blank"
              >Contact your RWDI project manager</a
            >
          </div>
        </template>
      </empty-state>
      <empty-state v-else-if="filteredProjects.length === 0 && searchingProjects && !currentTabLoading">
        <template v-slot:message>
          <p>No search results match your query</p>
        </template>
      </empty-state>
      <project-card v-else
        v-for="project in filteredProjects"
        :key="project.id"
        :project="project"
        @add-scenario="openCreateScenarioModal(project.id)"
      />
      <loading-state v-if="loadingNextPage && currentTabLoading" />

      <create-project-modal
        :show-modal="createProjectModalIsVisible"
        @close-modal="createProjectModalIsVisible = false"
      />
      <create-scenario-modal
        :show-modal="createScenarioModalIsVisible"
        :project-id="scenarioProjectId"
        @close-modal="closeCreateScenarioModal"
      />
      <create-scenario-success-modal
        :show-modal="createScenarioSuccessModalIsVisible"
        @close-modal="closeCreateScenarioSuccessModal"
      />
    </div>

    <eula-modal
      :show-modal="eulaModalIsVisible"
      :eula="eula"
      :submitAcceptedEula="submitAcceptedEula"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectCard from './ProjectCard';
import EmptyState from '@/components/views/EmptyState';
import LoadingState from '@/components/views/LoadingState';
import CreateProjectModal from './CreateProjectModal';
import CreateScenarioModal from './CreateScenarioModal';
import CreateScenarioSuccessModal from './CreateScenarioSuccessModal';
import EulaModal from '@/components/permissions/EulaModal';
import MarketingBar from './MarketingBar';

import { NotificationBanner, CustomButton } from 'rwdi-widgets';

import { ADD_PROJECT } from '@/constants/permissions';

import { GET_EULA, SUBMIT_ACCEPTED_EULA } from '@/store/actions/permissions';

import { EventBus } from '@/network/eventbus';

// using require instead of import as a workaround to this bug: https://stackoverflow.com/questions/49263429/jest-gives-an-error-syntaxerror-unexpected-token-export
let productFruitsInit = null;
if (process.env.NODE_ENV !== 'test') {
  productFruitsInit = require('product-fruits');
}

export default {
  name: 'ProjectList',
  pageTitle: 'Projects',
  components: {
    ProjectCard,
    EmptyState,
    LoadingState,
    NotificationBanner,
    CustomButton,
    CreateProjectModal,
    CreateScenarioModal,
    CreateScenarioSuccessModal,
    EulaModal,
    MarketingBar,
  },
  computed: {
    currentTabLoading() {
      return this.$store.getters['project/isLoadingProjects'][this.currentTab];
    },
    isTabReady() {
      return this.$store.getters['project/isTabReady'];
    },
    filteredProjects() {
      switch (this.currentTab) {
      case 'all':
        return this.projects;
      case 'favorites':
        return this.$store.getters['project/favoriteProjects'] || [];
      case 'shared':
        return this.$store.getters['project/sharedProjects'] || [];
      case 'demos':
        return this.$store.getters['project/demoProjects'] || [];
      default:
        return this.projects;
      }
    },
    eulaIsAccepted() {
      return this.$store.getters['eulaIsAccepted'];
    },
    errorMsg() {
      return this.$store.getters['project/error']
        ? 'Please contact support if you continue to see this error.'
        : null;
    },
    isLoading() {
      return this.$store.getters['project/loading'];
    },
    projects() {
      return this.$store.getters['project/allProjects']?.slice();
    },
    emptyStateImageAsset() {
      return require('@/assets/svg/empty-state.svg');
    },
    canAddProject() {
      return (this.$store.getters['allowedPermissions'] || {})[ADD_PROJECT];
    },
    createdScenarioInformation() {
      return this.$store.getters['project/createdScenarioInformation'];
    },
    createScenarioSuccessModalIsVisible() {
      return this.$store.getters['project/createScenarioSuccessModalVisible'];
    },
    ...mapGetters(['eula', 'loggedInUser']),
    ...mapGetters('project', ['productFruits']),
  },
  async created() {
    let searchQueryFromRoute = this.$route.query?.filter;
    if (searchQueryFromRoute) {
      this.allPagesLoaded = false;
      this.$store.dispatch('project/reset');
      this.executeSearch(searchQueryFromRoute);
    } else {
      if (!this.loggedInUser?.is_otp_user) this.startPagination();
    }
  },
  data() {
    return {
      projectCode: 'r8THtdxQNvKIzYmL', // referred to as a workspace code on product-fruits
      createProjectModalIsVisible: false,
      createScenarioModalIsVisible: false,
      eulaModalIsVisible: false,
      scenarioProjectId: null,
      loadingNextPage: false,
      allPagesLoaded: false,
      searchingProjects: false,
      searchProjectsQueryInput: '',
      searchProjectsQuery: '',
      productFruitsInit: productFruitsInit,
      currentTab: 'all',
      projectTabs: [
        { value: 'all', text: 'Projects' },
        { value: 'shared', text: 'Shared with Me' },
        { value: 'favorites', text: 'Favorites' },
        { value: 'demos', text: 'Sample Projects' },
      ],
      selectedOrder: 'name',
      orderingOptions: [
        { value: 'name', label: 'Name A-Z' },
        { value: '-name', label: 'Name Z-A' },
        { value: 'created_at', label: 'Oldest' },
        { value: '-created_at', label: 'Newest' },
      ],
    };
  },
  async mounted() {
    let t1 = this.$store.dispatch(GET_EULA);
    let t2 = this.$store.dispatch('project/getJobTypes');
    let t3 = this.$store.dispatch('project/getCriteria');
    window.addEventListener('scroll', this.getNextPageOnScrollToBottom);
    EventBus.$on('SUBMITML_COMPLETE', this.onSubmitComplete);
    await Promise.all([t1, t2, t3]);
  },
  beforeDestroy() {
    EventBus.$off('SUBMITML_COMPLETE', this.onSubmitComplete);
    window.removeEventListener('scroll', this.getNextPageOnScrollToBottom);
  },
  methods: {
    async updateOrdering() {
      this.allPagesLoaded = false;
      this.loadingNextPage = false;
      if(this.searchingProjects){
        this.$store.dispatch('project/reset');
        await this.getNextPageOnScrollToBottom();
      } else {
        await this.startPagination();
      }
      
    },
    setCurrentTab() {
      this.loadingNextPage = false;
      this.$store.commit('project/setCurrentTab', this.currentTab);
    },
    closeDropdownMenu() {
      EventBus.$emit('CLOSE_USER_DROPDOWN');
    },
    initializeProductFruits() {
      if (this.loggedInUser) {
        if (this.productFruitsInit && !this.productFruits) {
          const user = this.loggedInUser;
          let showDashTour = 2;
          if (user.role.includes('role: org admin')) showDashTour = 3;
          if (user.role.includes('role: viewer')) showDashTour = 1;
          const showTour = user.show_welcome_tour ? 1 : 0;
          const userInfoWithHMAC = {
            username: user.pf_user_id,
            props: {
              showTour: showTour,
              showDashTour: showDashTour,
            },
            hmac: {
              hash: user.usernameHMAC,
              expiration: null,
            },
          };
          let projectCode = this.projectCode;
          // Removing the VUE_APP_PRODUCT_FRUITS_TEST_PROJECT_CODE may resolve issues/errors related whitelisting the dashboard tour (403/401 errors)
          // Please note that the  VUE_APP_PRODUCT_FRUITS_TEST_PROJECT_CODE env should only be present when a tour(dashboard/project) is in development
          if (
            process.env.VUE_APP_PRODUCT_FRUITS_PROJECT_CODE &&
            String(process.env.VUE_APP_PRODUCT_FRUITS_PROJECT_CODE).length > 1
          )
            projectCode = String(
              process.env.VUE_APP_PRODUCT_FRUITS_PROJECT_CODE
            );
          this.closeDropdownMenu();
          productFruitsInit.productFruits.init(
            projectCode,
            'en',
            userInfoWithHMAC
          );
          this.$store.dispatch('project/setProductFruits', true);
        }
      }
    },
    submitAcceptedEula() {
      this.$store.dispatch(SUBMIT_ACCEPTED_EULA, { eula_id: this.eula.id });
    },
    openCreateProjectModal() {
      this.createProjectModalIsVisible = true;
    },
    openCreateScenarioModal(projectId) {
      this.createScenarioModalIsVisible = true;
      this.scenarioProjectId = projectId;
    },
    closeCreateScenarioModal(scenarioInformation) {
      this.createScenarioModalIsVisible = false;
      this.scenarioProjectId = null;
      if (scenarioInformation != null) {
        this.showViewer();
      }
    },
    showViewer() {
      this.$router.push({
        name: 'ViewerContainer',
        params: {
          id: this.createdScenarioInformation.projectId,
          study: this.createdScenarioInformation.studyId,
          configuration: this.createdScenarioInformation.configurationId,
          tabName: 'Viewer',
        },
      });
    },
    closeCreateScenarioSuccessModal() {
      this.$store.dispatch(
        'project/setCreateScenarioSuccessModalVisible',
        false
      );
    },
    async getNextPageOnScrollToBottom() {
      if (!this.loadingNextPage) {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          0.4 * document.documentElement.offsetHeight;

        if (bottomOfWindow && !this.allPagesLoaded) {
          try {
            this.loadingNextPage = true;
            if (this.searchingProjects) {
              switch (this.currentTab) {
              case 'all':
                await this.$store.dispatch('project/getSearchPage', {
                  searchTerm: this.searchProjectsQuery,
                  order: this.selectedOrder
                });
                break;
              case 'favorites':
                await this.$store.dispatch('project/getFavoritesSearchPage', {
                  searchTerm: this.searchProjectsQuery,
                  order: this.selectedOrder
                });
                break;
              case 'shared':
                await this.$store.dispatch('project/getSharedSearchPage', {
                  searchTerm: this.searchProjectsQuery,
                  order: this.selectedOrder
                });
                break;
              case 'demos':
                await this.$store.dispatch('project/getDemosSearchPage', {
                  searchTerm: this.searchProjectsQuery,
                  order: this.selectedOrder
                });
                break;
              }
            } else if (this.filteredProjects.length !== 0) {
              switch (this.currentTab) {
              case 'all':
                await this.$store.dispatch('project/getPage', this.selectedOrder);
                break;
              case 'favorites':
                await this.$store.dispatch('project/getFavoriteProjects', this.selectedOrder);
                break;
              case 'shared':
                await this.$store.dispatch('project/getSharedProjects', this.selectedOrder);
                break;
              case 'demos':
                await this.$store.dispatch('project/getDemoProjects', this.selectedOrder);
                break;
              }
            }
            this.loadingNextPage = false;
          } catch (e) {
            this.allPagesLoaded = true;
            this.loadingNextPage = false;
          }
        }
      }
    },
    cancelSearch() {
      this.searchingProjects = false;
      this.searchProjectsQuery = '';
      this.searchProjectsQueryInput = '';
      this.$router.push({ path: this.$route.path });
      this.startPagination();
    },
    async searchProjects() {
      if (this.searchProjectsQueryInput !== '') {
        await this.executeSearch(this.searchProjectsQueryInput);
        this.$router.push({ path: this.$route.path, query: { filter: this.searchProjectsQueryInput } });
      }
    },
    async executeSearch(searchString) {
      //this can be called from:
      // - the searchProjects() method above (when a user types in the search string)
      // - from the created() hook, if a url that contains the search filter in the query string
      // - from a watch on $route, which triggers if a user clicks 'back' in the browser which re-adds a removed search string

      this.allPagesLoaded = false;
      this.searchingProjects = true;
      this.loadingNextPage = false;
      this.searchProjectsQuery = searchString;
      // Doing search for all tabs
      await this.$store.dispatch('project/reset');
      this.$store.dispatch('project/getProjectsByName', { 
        name: this.searchProjectsQuery,
        order: this.selectedOrder
      });
      this.$store.dispatch('project/getFavoriteProjectsByName', {
        name: this.searchProjectsQuery,
        order: this.selectedOrder
      });
      this.$store.dispatch('project/getSharedProjectsByName', {
        name: this.searchProjectsQuery,
        order: this.selectedOrder
      });
      this.$store.dispatch('project/getDemoProjectsByName', {
        name: this.searchProjectsQuery,
        order: this.selectedOrder
      });
    },
    async startPagination() {
      this.allPagesLoaded = false;
      await this.$store.dispatch('project/reset');
      this.loadingNextPage = true;
      let t1 = this.$store.dispatch('project/getPage', this.selectedOrder);
      let t2 = this.$store.dispatch('project/getFavoriteProjects', this.selectedOrder);
      let t3 = this.$store.dispatch('project/getSharedProjects', this.selectedOrder);
      let t4 = this.$store.dispatch('project/getDemoProjects', this.selectedOrder);
      await Promise.all([t1, t2, t3, t4]);
      this.loadingNextPage = false;
    },
    onSubmitComplete(args) {
      const projectId = parseInt(args.project_id);
      const configId = parseInt(args.scenario_id);

      const matchingProject = this.projects.find((x) => x.id == projectId);
      if (matchingProject) {
        const simulation = matchingProject.studies[0].simulation_labels.find(
          (x) => x.configurationId == configId
        );
        if (simulation) {
          this.setSimulationCategory({
            projectId: projectId,
            simulationId: simulation.id,
            newCategoryValue: 'RESULT',
          });
        }
      }
    },
    ...mapActions({
      setSimulationCategory: 'project/setSimulationCategory',
    }),
  },
  watch: {
    eulaIsAccepted(newValue) {
      if (newValue) this.initializeProductFruits();
    },
    async webSocketConnectionInfo(newValue) {
      if (newValue) {
        await this.connectToWebSocket(newValue);
      }
    },
    eula(newValue) {
      this.eulaModalIsVisible = !!newValue;
    },
    projects(newValue) {
      if (newValue) {
        if (newValue.length === 0) this.allPagesLoaded = false;
      }
    },
  },
};
</script>

<style>
.productfruits--container {
  margin-top: 0;
}

#projectTypeFilters .btn-group-toggle .btn-outline.active {
  border-bottom-color: var(--primary-cerulean);
  border-bottom-width: 0.188rem;
}

#projectTypeFilters .btn-group-toggle .btn-outline span {
  color: var(--grey-800);
  font-weight: bold;
}
</style>

<style scoped>

#projectTypeDivider {
  margin: 0 auto;
  width: calc(100% - (1.5em + 1vw));
  max-width: 73.5rem;
  height: 4px;
  margin-top: -20px;
  margin-bottom: 1rem;
  border-color: var(--grey-200);
  background-color: var(--grey-400);
}



.project-list-container {
  display: flex;
  flex-direction: column;
}

.list-container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 75rem;
  width: calc(100% - (1.5em + 1vw));
  padding: 0 0.5em calc(1.5em + 1vw);
}

.tabs-container {
  margin: 0 auto;
  margin-bottom: 15px;
  display: flex;
  justify-content: left;
  max-width: 73.5rem;
  width: calc(100% - (1.5em + 1vw));
}

.actions-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: calc(100% - (1.5em + 1vw));
  max-width: 73.5rem;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: calc(100% - (1.5em + 1vw));
  max-width: 75rem;
  padding: 1vw 0.5em;
}

.notification-banner-container {
  margin: 0 auto;
  width: 100%;
  max-width: 75rem;
  padding: calc(1.5em + 1vw) 0.5em;
}

.input-container {
  display: flex;
  flex-direction: row;
}

.input-container > * {
  width: 50%;
  margin-top: 0;
}

.input-container span {
  display: flex;
  align-items: flex-end;
  padding: 0.6em 1em 0.5em;
}

.input-container label {
  margin-bottom: 0.5em;
}

.button-container > * {
  margin-top: 0;
}

.loading-spinner {
  height: 1.25em;
  width: 1.25em;
  padding: 0 1.5rem;
}

.ordering-section {
  margin-top: 0;
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -6rem;
  
}

.ordering-section label {
  color: var(--grey-600);
  font-size: 0.875rem;
  margin-right: 0.5rem;
  margin-top: 0.75rem;
}

.button-section {
  margin-top: 0;
  grid-column: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.search-input {
  margin: 0;
  width: 100%;
  height: 38px;
}

.ordering-container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.ordering-select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  height: 24px;
  min-width: 150px;
}

.order-direction-button {
  padding: 8px;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.order-icon {
  font-size: 16px;
}

custom-button {
  height: 38px;
  display: flex;
  align-items: center;
}
</style>