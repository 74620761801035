<template>
  <div class="empty-state">
    <img :src="image" :alt="imageAltText"/>
    <slot name="title"></slot>
    <slot name="message"></slot>
    <slot name="call-to-action"></slot>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    image: {
      type: String,
    },
    imageAltText: {
      type: String,
    }
  }
};
</script>

<style scoped>
.empty-state {
  text-align: center;
  max-width: 25rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.empty-state img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 18.75rem;
  margin: 0 auto 1em;
}

.empty-state a {
  color: #fff;
  text-decoration: none;
  margin: 0 auto;
}
</style>